
import $ from 'jquery'
//两个数组比较是否相同
export function compareArraySort(a1, a2) {
  if ((!a1 && a2) || (a1 && !a2)) return false;
  if (a1.length !== a2.length) return false;
  a1 = [].concat(a1);
  a2 = [].concat(a2);
  a1 = a1.sort();
  a2 = a2.sort();
  for (var i = 0, n = a1.length; i < n; i++) {
    if (a1[i] !== a2[i]) return false;
  }
  return true;
}

//禁止按空格键滚动页面
export function preventSpaceScroll(el){
el.keydown(function (event) {
  var e = window.event || event;
  if (event.keyCode == 32) {
    if (e.preventDefault) {
        e.preventDefault();
    } else {
      window.event.returnValue = false;
    }
    return false
  }
})
}
