<template>
  <div class="context">
    <!-- 头部 -->
    <div class="header">
      <!-- 搜索框 -->
      <el-input
        @keyup.enter.native="search"
        placeholder="请输入素材名称或关键字"
        v-model="searchValue"
      >
        <el-select
          @change="selectVideoandImg"
          class="search"
          v-model="videoOrimg"
          :popper-append-to-body="false"
          slot="prepend"
          placeholder="视频"
        >
          <el-option label="视频" value="video"></el-option>
          <el-option label="图片" value="img"></el-option>
        </el-select>
      </el-input>
      <!-- 搜索图标按钮 -->
      <div class="searchButton">
        <i
          style="width: 25px; height: 32px; cursor: pointer; padding-top: 15px"
          class="iconfont icon-sousuo"
          @click="search"
        ></i>
      </div>
      <div style="width: 26px"></div>
      <!-- 筛选 -->
      <div class="screen" @click="showScreen" :style="{ background: color }">
        <img
          style="width: 16px; height: 17px"
          src="@/assets/images/screen.png"
          alt=""
        />
      </div>
    </div>

     <!-- 高级筛选弹框 -->
      <div class="screen_container" v-show="isshow">
        <div style="width: 64px"></div>
        <!-- 筛选内容 -->
        <div class="screen_context">
          <!-- 上传时间 -->
          <div class="block">
            <span class="demonstration">上传时间</span>
            <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              prefix-icon="el-icon-date"
              value-format="yyyy-MM-dd"
              clear-icon="el-icon-close "
              :clearable="true"
              :default-value="timeDefaultShow"
            >
            </el-date-picker>
          </div>
          <el-divider></el-divider>
          <!-- 标签 -->
          <div class="label" style="display: flex">
            <span class="Label">标签</span>
            <el-select
              :popper-append-to-body="false"
              :class="['Label_select',labelList.length?'':'disable_select']"
              v-model="label"
              slot="prepend"
              placeholder="任意符合"
              :disabled="labelList.length?false:true"
            >
              <el-option label="任意符合" value="1"></el-option>
              <el-option label="同时符合" value="2"></el-option>
            </el-select>
          </div>
          <!-- 复选框 -->
          <div class="checkgroup">
            <!-- 全部 -->
            <el-checkbox
              :style="{ opacity: label == '1' ? 1 : 0 }"
              class="checkedAll"
              v-model="checkAll"
              @change="handleCheckAllChange"
              v-if="labelList.length"
            >
              全选</el-checkbox
            >
            <!-- 复选框组 -->
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
              :max="20"
            >
              <!-- 普通复选框 -->
              <el-checkbox
                v-for="item in labelList"
                :checked="$route.params.tag_id == item.id ? true : false"
                :label="item.tag_name"
                :key="item.id"
              ></el-checkbox>
            </el-checkbox-group>
          </div>
          <el-divider></el-divider>
          <!-- 其他 -->
          <div class="other">
            <span style="height: 30px; line-height: 30px">其他</span>
            <!-- 分辨率 -->
            <el-select
              collapse-tags
              multiple
              :popper-append-to-body="false"
              class="resolving"
              v-model="resolving"
              slot="prepend"
              placeholder="分辨率"
            >
              <el-option
                v-for="(item, index) in resolveData"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <!-- 比例 -->
            <el-select
              collapse-tags
              multiple
              :popper-append-to-body="false"
              class="scale"
              v-model="scale"
              slot="prepend"
              placeholder="比例"
            >
              <el-option
                v-for="(item, index) in scaleData"
                :key="index"
                :label="item | formatScale"
                :value="item"
              ></el-option>
            </el-select>
            <!-- 格式 -->
            <el-select
              collapse-tags
              multiple
              :popper-append-to-body="false"
              class="format"
              v-model="format"
              slot="prepend"
              placeholder="格式"
            >
              <el-option
                v-for="(item, index) in formatData"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <!-- 上传者 -->
            <el-cascader
              :key="cascaderKey"
              :props="{ multiple: true }"
              :collapse-tags="true"
              :show-all-levels="false"
              popper-class="upload-user"
              :popper-append-to-body="false"
              placeholder="上传者"
              class="upload"
              v-model="upUser"
              :options="options"
              ref="cascader"
            ></el-cascader>
          </div>
          <!-- 重置跟筛选按钮 -->
          <div class="Btn">
            <el-button style="margin-right: 20px" @click="reset"
              >重置</el-button
            >
            <el-button style="margin-right: 42px" @click="filter"
              >筛选</el-button
            >
          </div>
        </div>
        <div style="width: 80px"></div>
      </div>
     <!-- 高级筛选弹框 -->

    <!-- 高级筛选，视频，图片区域 -->
    <div
      v-if="none == false"
      class="container"
      :style="isshow?'height:40vh':'height:83vh'"
      ref="middleListContent"
      v-loading="ifLoading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      element-loading-text="素材加载中..."
    >

      <!-- 视频内容区域 -->
      <div
        v-if="videoOrimg == 'video'"
        class="videoContext"
        style="
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div>
          <!-- 统计跟排序 -->
          <div class="totalORorder">
            <div class="total">共{{ videoTotal }}个素材</div>
            <div class="order">
              <div>排序：</div>
              <div class="default" v-show="showDefault">
                <el-button
                  :disabled="videoTotal == 0 ? true : false"
                  @click="Default"
                  :style="`background:${num == 0 ? '#2D2D3D' : '#212130 '}`"
                  >默认
                  <i v-if="defaultArrow == 'bottom'" class="el-icon-bottom"></i>
                  <i v-if="defaultArrow == 'top'" class="el-icon-top"></i>
                </el-button>
              </div>
              <div class="uptime">
                <el-button
                  :disabled="videoTotal == 0 ? true : false"
                  @click="Uptime"
                  :style="`background:${
                    num == 1 ? '#2D2D3D' : '#212130 '
                  };border-radius:${
                    showDefault == true ? '0px 0px 0px 0px' : '6px 0px 0px 6px'
                  };`"
                  >上传时间
                  <i v-if="uploadArrow == 'bottom'" class="el-icon-bottom"></i>
                  <i v-if="uploadArrow == 'top'" class="el-icon-top"></i
                ></el-button>
              </div>
              <div class="time">
                <el-button
                  :disabled="videoTotal == 0 ? true : false"
                  @click="Time"
                  :style="`background:${num == 2 ? '#2D2D3D' : '#212130 '}`"
                  >时长<i
                    v-if="timeArrow == 'bottom'"
                    class="el-icon-bottom"
                  ></i>
                  <i v-if="timeArrow == 'top'" class="el-icon-top"></i>
                </el-button>
              </div>
            </div>
          </div>
          <!-- 视频区域 -->
          <div v-if="noResult == 0" class="allVideo">
            <div class="video"  v-for="(item,index) in videoData" :key="item.ip_id">
              <!-- 视频 -->
              <div class="videoSize" :id="item.ip_id" @mouseleave="imgLeave"  @mouseenter="imgEnter" @click="goMaterialDetail(item.ip_id)">
                <!-- 自定义collet属性为了在video默认的单击事件中传递 -->
                <img
                 :class="['gifNode',item.gifNode ? 'gifNode_show':'']"
                  style="cursor: pointor"
                  :src="item.gifNode"
                  alt=""
                />
                <img
                 class="imgNode"
                  style="cursor: pointor"
                  :src="item.cover_img"
                  v-if="item.showImg"
                  alt=""
                />
                <i class="iconfont icon-shipin1" v-if="!item.cover_img"></i>
              </div>
              <!-- 下载,加入观看，收藏列表 -->
              <div
                class="btns"
              >
                <img
                  @click="download(item.url, item.ip_id)"
                  class="download"
                  src="@/assets/images/down.png"
                  alt=""
                />
                <img
                  class="add"
                  src="@/assets/images/add.png"
                  alt=""
                  @click="addList(item.ip_id)"
                />
                <!-- 收藏按钮 -->
                <div @click="addCollet(item.ip_id)">
                  <img
                    class="collet"
                    v-if="item.collect_if == 1"
                    src="@/assets/images/colleted.png"
                  />
                  <img class="collet" v-else src="@/assets/images/collet.png" />
                </div>
              </div>
              <!-- 视频时长 -->
              <span class="duration">{{ item.duration }}</span>
              <!-- 视频底部标题 -->
              <el-tooltip
                v-delTabIndex
               :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'"
               popper-class="my_material_tooltip"
                :content="item.name"
              >
                <div
                  class="videoTitle one_line_ellipsis"
                  @click="goMaterialDetail(item.ip_id)"
                >
                  {{ item.name }}
                </div>
              </el-tooltip>
            </div>
          </div>
          <noResult :style="isshow?'margin-top:5%':'margin-top:15%'" v-if="noResult == 2" iconUrl="icon-meiyoujieguo" iconSty="font-size:80px" textSty="font-size:18px" noResultText="没有符合条件的素材"></noResult>
        </div>
        <!-- 分页 -->
        <el-pagination
          :background="true"
          v-show="noResult == 0 && ifLoading == false"
          @current-change="handleCurrentChange"
          :current-page="videopage"
          layout="prev, pager, next, jumper"
          :page-size="40"
          :total="videoOrimg == 'video' ? videoTotal : imgTotal"
          :hide-on-single-page="true"
        >
        </el-pagination>

      </div>
      <!-- 图片内容区域 -->
      <div
        v-if="videoOrimg == 'img'"
        class="videoContext"
        style="
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div>
          <!-- 统计跟排序 -->
          <div class="totalORorder">
            <div class="total">共{{ imgTotal }}个素材</div>
            <div class="order">
              <div>排序：</div>
              <div class="default" v-show="showDefault">
                <el-button
                  @click="Default"
                  :style="`background:${num == 0 ? '#2D2D3D' : '#212130 '}`"
                  >默认<i
                    v-if="defaultArrow == 'bottom'"
                    class="el-icon-bottom"
                  ></i>
                  <i v-if="defaultArrow == 'top'" class="el-icon-top"></i>
                </el-button>
              </div>
              <div class="uptime">
                <el-button
                  @click="Uptime"
                  :style="`background:${
                    num == 1 ? '#2D2D3D' : '#212130 '
                  };border-radius:${
                    showDefault == true ? '0px 6px 6px 0px' : '6px'
                  };`"
                  >上传时间<i
                    v-if="uploadArrow == 'bottom'"
                    class="el-icon-bottom"
                  ></i>
                  <i v-if="uploadArrow == 'top'" class="el-icon-top"></i
                ></el-button>
              </div>
            </div>
          </div>
          <!-- 图片区域 -->
          <div class="allImg" v-if="noResult == 0">
            <div class="video" v-for="(item,index) in imgData" :key="item.ip_id">
              <!-- 图片 -->
              <div
                class="videoSize img_size"
                @click="goMaterialDetail(item.ip_id)"
              >
                <img :src="item.cover" alt="" />
              </div>
              <!-- 下载,收藏按钮 -->
              <div class="btns">
                <!-- 下载按钮 -->
                <img
                  class="download"
                  src="@/assets/images/down.png"
                  alt=""
                  @click="download(item.url, item.name)"
                />
                <!-- 收藏按钮 -->
                <div @click="addCollet(item.ip_id)">
                  <img
                    class="collet"
                    v-if="item.collect_if == 1"
                    src="@/assets/images/colleted.png"
                  />
                  <img class="collet" v-else src="@/assets/images/collet.png" />
                </div>
              </div>
              <!-- 图片底部标题 -->
              <el-tooltip
               v-delTabIndex
               :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'"
               popper-class="my_material_tooltip"
                :content="item.name"
              >
                <div
                  class="videoTitle one_line_ellipsis"
                  @click="goMaterialDetail(item.ip_id)"
                >
                  {{ item.name }}
                </div>
              </el-tooltip>
            </div>
          </div>
          <noResult :style="isshow?'margin-top:5%':'margin-top:15%'" v-if="noResult == 2" iconUrl="icon-meiyoujieguo" iconSty="font-size:80px" textSty="font-size:18px" noResultText="没有符合条件的素材"></noResult>
        </div>
        <!-- 分页 -->
        <el-pagination
          :background="true"
          :current-page="videopage"
          v-show="noResult == 0 && ifLoading == false"
          @current-change="handleCurrentChange"
          layout="prev, pager, next, jumper"
          :page-size="40"
          :total="videoOrimg == 'video' ? videoTotal : imgTotal"
          :hide-on-single-page="true"
        >
        </el-pagination>

      </div>
    </div>
    <noResult :style="isshow?'margin-top:10%;height:auto;':''" v-else iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂时没有素材"></noResult>
    <!-- 下载提示对话框 -->
    <el-dialog
      title="下载"
      :visible.sync="downloadDialog"
      width="461px"
      top="30vh"
      custom-class="material-dialog"
      :show-close="true"
    >
      <span style="display: block; margin-bottom: 21px">分辨率：</span>
      <div class="radio_check">
        <el-radio
          label="标清"
          v-if="resolutions.length == 1"
          class="disable_radio"
          >标清</el-radio
        >
        <el-radio
          v-for="(item, index) in resolutions"
          :key="index"
          v-model="system"
          :label="item.url"
          >{{ item.resolution }}</el-radio
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadConfirm">确 定</el-button>
        <el-button @click="downloadDialog = false" style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import Player from "xgplayer";
import { preventSpaceScroll } from "../plugins/solvemethod";
import noResult from "../component/noResult.vue";
import $ from "jquery";
export default {
  inject: ["reload"],
  components: {
    noResult,
  },
  data() {
    return {
      // 日期便捷选择
      pickerOptions: {
        shortcuts: [
          {
            text: "近七天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      timeDefaultShow: "",
      //做选择视频跟选择图片的缓存
      videoOrimg: this.getCache(),
      // 显示隐藏筛选内容
      isshow: false,
      // 筛选按钮背景色
      color: "",
      // 排序按钮做缓存, 0,1,2依次为三个按钮的选中颜色
      num: 1,
      // 默认按钮箭头做缓存
      defaultArrow: "bottom",
      // 上传时间按钮箭头做缓存
      uploadArrow: "bottom",
      // 时长按钮箭头做缓存
      timeArrow: "bottom",
      showDefault: false, //显示隐藏默认排序按钮
      // 标签下拉选择框的值
      label: "1",
      // 分辨率下拉框的值
      resolving: "",
      // 分辨率下拉框列表数据
      resolveData: [],
      // 比例下拉框的值
      scale: "",
      // 比例下拉框列表数据
      scaleData: [],
      // 格式下拉框的值
      format: "",
      // 格式下拉框列表数据
      formatData: [],
      // 上传者
      upUser: "",
      // 上传者列表数据
      options: [],
      cascaderKey: 0,
      // 显示收藏下载等按钮
      showbtns: false,
      // 视频总计
      videoTotal: 0,
      // 图片总计
      imgTotal: 0,
      // 视频数据
      videoData: [],
      // 图片数据
      imgData: [],
      // 搜索框绑定的值
      searchValue: "",
      // 日期范围
      date: "",
      checkAll: false, // 全选框
      checkedCities: [], // 复选框默认选中的选项
      labelList: [], //标签列表数据
      // 显示隐藏下载对话框
      downloadDialog: false,
      // 下载对话框选中的选项
      system: "",
      downSourse: "", //下载源
      resolutions: [], //下载的分辨率
      ifLoading: true,
      noResult: 0, //没有符合条件的素材
      none: false, //素材为空
      page: 1, //当前页
      videopage: 0,
    };
  },
  methods: {
    filterNum (n) {
      if (n % 5 == 1 || n % 5 == 2 || n % 5 == 3 ) {
        return true
      }else{
        return false
      }
    },
    // 搜索
    search() {
      this.videopage = 1;
      // 关键词搜索视频
      if (this.searchValue.trim() == "") {
        this.$message.error("请输入素材名称或关键字");
      } else {
        if (this.videoOrimg == "video") {
          // 打开默认按钮
          this.num = 0;
          // 设置请求参数
          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);
          let formData = this.setRequestData([
            { search_name: this.searchValue },
            { search_type: 1 },
            { sort_type2: 1 },
            { tag_type: this.getLabelId() == "" ? "" : this.label },
            { tag_id_list: this.getLabelId() },
            { page: 1 },
            { start_time: !this.date ? "" : this.date[0] },
            { end_time: !this.date ? "" : this.date[1] },
            { resolution: this.resolving.join(",") },
            { scale: this.scale.join(",") },
            { format: this.format.join(",") },
            { uploader: upuser1 },
          ]);
          this.getVideo(formData, "1");
        }
        // 关键词搜索图片
        if (this.videoOrimg == "img") {
          // 打开默认按钮
          this.num = 0;
          // 设置请求参数
          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);
          let formData = this.setRequestData([
            { search_name: this.searchValue },
            { search_type: 2 },
            { sort_type2: 1 },
            { tag_type: this.getLabelId() == "" ? "" : this.label },
            { tag_id_list: this.getLabelId() },
            { page: 1 },
            { start_time: !this.date ? "" : this.date[0] },
            { end_time: !this.date ? "" : this.date[1] },
            { resolution: this.resolving.join(",") },
            { scale: this.scale.join(",") },
            { format: this.format.join(",") },
            { uploader: upuser1 },
          ]);
          this.getImg(formData, "1");
        }
      }
    },
    //做选择视频跟选择图片的缓存
    getCache() {
      // 如果缓存为空，默认视频video
      if (sessionStorage.getItem("videoOrimg") == null) {
        return "video";
        //如果缓存不为空,默认图片 img
      } else {
        return sessionStorage.getItem("videoOrimg");
      }
    },
    //跳转至素材详情
    goMaterialDetail(id) {
      const { href } = this.$router.resolve({
        name: "materialDetail",
        query: { ip_id: id, videoOrimg: this.videoOrimg },
      });
      window.open(href, "_blank");
    },
    // 选择视频或者图片
    selectVideoandImg(e) {
      e.stopPropagation;
      // 清空搜索框值
      this.searchValue = "";
      // 如果筛选页容器打开，每次选择图片或视频时重置筛选项
      if (this.isshow == true) {
        // 重置按钮
        this.reset();
      }

      // 选择视频
      if (e == "video") {
        // 做缓存
        sessionStorage.setItem("videoOrimg", "video");
        //请求标签
        this.getLabel();
        // 请求上传者下拉框的数据
        this.getUpUserData();
        // 请求视频分辨率，比例，格式下拉框的数据
        this.getdowndata("1");
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 1 },
          { sort_type: "ut" },
          { sort_type2: 1 },
        ]);
        this.getVideo(formData);
      }
      //选择图片
      if (e == "img") {
        // 做缓存
        sessionStorage.setItem("videoOrimg", "img");
        //请求标签
        this.getLabel();
        // 请求上传者下拉框的数据
        this.getUpUserData();
        // 请求图片分辨率，比例，格式下拉框的数据
        this.getdowndata("2");
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 2 },
          { sort_type: "ut" },
          { sort_type2: 1 },
        ]);
        this.getImg(formData);
      }
    },
    //  视频鼠标移入
    imgEnter(e) {
      this.videoData.forEach((item,i)=>{
      item.gifNode = ''
      if(e.target.id == item.ip_id){
        item.gifNode = item.cover
        item.imgNode = ''
        item.showImg = false
      }
      })

    },
    // 视频鼠标移出
    imgLeave(e) {
       this.videoData.forEach((item,i)=>{
        item.gifNode = ''
        item.showImg = true
      })
    },
    // 全选按钮事件
    handleCheckAllChange(val) {
      // 全选
      if (val == true) {
        for (let item of this.labelList) {
          this.checkedCities.push(item.tag_name);
        }
        // 数组去重 去掉重复添加到标签数组中的
        for (let i = 0; i < this.checkedCities.length; i++) {
          for (let j = i + 1; j < this.checkedCities.length; j++) {
            if (this.checkedCities[i] == this.checkedCities[j]) {
              this.checkedCities.splice(j, 1);
              j--;
            }
          }
        }
      }
      // 全不选
      else {
        this.checkedCities = [];
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.labelList.length;
    },
    // 添加/取消收藏
    addCollet(id) {
      let tp = this.videoOrimg == "video" ? 1 : 2;
      let formData = new FormData();
      formData.append("ip_id", id);
      formData.append("type", tp);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_collect",
        data: formData,
      })
        .then((res) => {
          // 默认根据时间倒序搜索视频
          if (this.getCache() == "video") {
            if (res.data.msg == "收藏成功") {
              for (let item of this.videoData) {
                if (id == item.ip_id) {
                  item.collect_if = 1;
                }
              }
            } else {
              for (let item of this.videoData) {
                if (id == item.ip_id) {
                  item.collect_if = 0;
                }
              }
            }
          }
          // 默认根据时间倒序图片
          if (this.getCache() == "img") {
            if (res.data.msg == "收藏成功") {
              for (let item of this.imgData) {
                if (id == item.ip_id) {
                  item.collect_if = 1;
                }
              }
            } else {
              for (let item of this.imgData) {
                if (id == item.ip_id) {
                  item.collect_if = 0;
                }
              }
            }
          }
        })
        .catch((error) => console.log(error));
    },
    // 添加播放列表事件
    addList(id) {
      let formData = new FormData();
      formData.append("ip_id", id);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_watch_record",
        data: formData,
      })
        .then((res) => {
          // 加入成功
          if (res.data.status == 0) {
            this.$store.commit("editAdd", true);
            setTimeout(() => {
              this.$store.commit("editAdd", false);
            }, 2000);
          }
        })
        .catch((error) => console.log(error));
    },
    // 下载按钮
    download(downSourse, name) {
      // 将下载源保存
      this.downSourse = downSourse;
      // 如果是视频
      if (this.videoOrimg == "video") {
        let formData = new FormData();
        formData.append("ip_id", name);
        this.$http({
          url: "/upload/get_download_res",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.status == 0) {
              this.resolutions = res.data.result;
              this.system = res.data.result[0].url;
              // 弹出下载框
              this.downloadDialog = true;
            }
          })
          .catch((error) => console.log(error));
      }
      //如果是图片
      if (this.videoOrimg == "img") {
        this.downloadImg(this.downSourse, name);
      }
    },
    // 下载对话框确认事件
    downloadConfirm() {
      // 切换视频配置源 然后视频下载。
      let link = document.createElement("a");
      link.href = this.system; //链接
      document.body.appendChild(link); //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link); // 下载完成移除元素
      this.downloadDialog = false;
    },
    // 下载图片 传入下载地址
    downloadImg(imgsrc, name) {
      let download_name = "";
      let link = document.createElement("a");
      link.download = download_name; // 下载文件的名字
      link.href = imgsrc; //链接
      document.body.appendChild(link); //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link); // 下载完成移除元素
    },
    // 筛选按钮
    showScreen() {
      this.isshow = !this.isshow;
      this.isshow ? (this.color = "#2D2D3D") : (this.color = "");
    },
    // 默认按钮事件
    Default() {
      // 如果当前按钮是选中状态
      if (this.num == 0) {
        // 如果是下箭头
        if (this.defaultArrow == "bottom") {
          // 变为上箭头
          this.defaultArrow = "top";
          // 处理上传者数据
            let upuser = "";
            for (let i = 0; i < this.upUser.length; i++) {
              for (let item of this.upUser[i]) {
                if (item != undefined && item != 0) {
                  upuser += item + ",";
                }
              }
            }
            let upuser1 = upuser.substr(0, upuser.length - 1);
// 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
        // 如果是上箭头
        else {
          // 变为下箭头
          this.defaultArrow = "bottom";
          // 处理上传者数据
            let upuser = "";
            for (let i = 0; i < this.upUser.length; i++) {
              for (let item of this.upUser[i]) {
                if (item != undefined && item != 0) {
                  upuser += item + ",";
                }
              }
            }
            let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
      }
      // 如果当前按钮不是选中状态
      else {
        // 将按钮设为选中
        this.num = 0;
        // 如果是下箭头
        if (this.defaultArrow == "bottom") {
          // 变为下箭头
          this.defaultArrow = "bottom";

          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间倒序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间倒序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
        // 如果是上箭头
        else {
          // 变为上箭头
          this.defaultArrow = "top";

          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);
          // 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
      }
    },
    // 上传时间按钮事件
    Uptime() {
      // 如果当前按钮是选中状态
      if (this.num == 1) {
        // 如果是下箭头
        if (this.uploadArrow == "bottom") {
          // 变为上箭头
          this.uploadArrow = "top";
          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "ut" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "ut" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
        // 如果是上箭头
        else {
          // 变为下箭头
          this.uploadArrow = "bottom";

          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "ut" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "ut" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
      }
      // 如果当前按钮不是选中状态
      else {
        // 将按钮设为选中
        this.num = 1;
        // 如果是下箭头
        if (this.uploadArrow == "bottom") {
          // 变为下箭头
          this.uploadArrow = "bottom";

          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间倒序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "ut" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间倒序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "ut" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
        // 如果是上箭头
        else {
          // 变为上箭头
          this.uploadArrow = "top";
          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "ut" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "ut" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
      }
    },
    // 时长按钮事件
    Time() {
      // 如果当前按钮是选中状态
      if (this.num == 2) {
        // 如果是下箭头
        if (this.timeArrow == "bottom") {
          // 变为上箭头
          this.timeArrow = "top";

          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间正序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "duration" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间正序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "duration" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
        // 如果是上箭头
        else {
          // 变为下箭头
          this.timeArrow = "bottom";

          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);

          // 如果是当前选择的是视频 根据时间倒序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "duration" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间倒序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "duration" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
      }
      // 如果当前按钮不是选中状态
      else {
        // 将按钮设为选中
        this.num = 2;
        // 如果是下箭头
        if (this.timeArrow == "bottom") {
          // 变为下箭头
          this.timeArrow = "bottom";
          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);
          // 如果是当前选择的是视频 根据时间倒序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "duration" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间倒序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "duration" },
              { sort_type2: 1 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
        // 如果是上箭头
        else {
          // 变为上箭头
          this.timeArrow = "top";
          // 处理上传者数据
          let upuser = "";
          for (let i = 0; i < this.upUser.length; i++) {
            for (let item of this.upUser[i]) {
              if (item != undefined && item != 0) {
                upuser += item + ",";
              }
            }
          }
          let upuser1 = upuser.substr(0, upuser.length - 1);
          // 如果是当前选择的是视频 根据时间顺序搜索视频
          if (this.videoOrimg == "video") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 1 },
              { sort_type: "duration" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getVideo(formData);
          }
          // 如果是当前选择的是图片 根据时间顺序搜索图片
          if (this.videoOrimg == "img") {
            // 设置请求参数
            let formData = this.setRequestData([
              { search_type: 2 },
              { sort_type: "duration" },
              { sort_type2: 0 },
              { search_name: this.searchValue.trim() },
              { page: this.page },

              { tag_type: this.getLabelId() == "" ? "" : this.label },
              { tag_id_list: this.getLabelId() },
              { start_time: !this.date ? "" : this.date[0] },
              { end_time: !this.date ? "" : this.date[1] },
              { resolution: this.resolving.join(",") },
              { scale: this.scale.join(",") },
              { format: this.format.join(",") },
              { uploader: upuser1 },
            ]);
            this.getImg(formData);
          }
        }
      }
    },
    // 获取视频
    getVideo(formData, log) {
      // 请求视频
      this.videoData = [];
      this.ifLoading = true;
      this.$http({
        url: "vw_material/get_all_ip",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          // 成功
          if (res.data.status == 0) {
            // 当前没有符合条件的素材
            if (log == "1" && res.data.result.length == 0) {
              this.ifLoading = false;
              this.videoTotal = res.data.total;
              this.noResult = 2;
              return;
            }
            // 根本没素材
            if (res.data.result.length == 0) {
              this.videoTotal = res.data.total;
              this.ifLoading = false;
              this.none = true;
              return;
            }
            this.noResult = 0;
            this.none = false;
            this.ifLoading = false;
            this.videoTotal = res.data.total;
            res.data.result.forEach((item,i)=>{
              item.gifNode = ''
              item.showImg = true
            })
            this.videoData = res.data.result;
            if (this.searchValue != "") {
              this.showDefault = true;
            }
          }
        })
        .catch((error) => console.log(error));
    },
    // 获取图片
    getImg(formData, log) {
      this.imgData = [];
      this.ifLoading = true;
      this.$http({
        url: "vw_material/get_all_ip",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          // 成功
          if (res.data.status == 0) {
            // 当前没有符合条件的素材
            if (log == "1" && res.data.result.length == 0) {
              this.ifLoading = false;
              this.noResult = 2;
              this.imgTotal = res.data.total;
              return;
            }
            if (res.data.result.length == 0) {
              this.ifLoading = false;
              this.none = true;
              this.imgTotal = res.data.total;
              return;
            }
            this.noResult = 0;
            this.none = false;
            this.ifLoading = false;
            this.imgTotal = res.data.total;
            this.imgData = res.data.result;
            if (this.searchValue != "") {
              this.showDefault = true;
            }
          }
        })
        .catch((error) => console.log(error));
    },
    // 封装请求参数函数
    setRequestData(dataArr) {
      let formData = new FormData();
      for (let item of dataArr) {
        //  遍历将每个对象的key跟value保存进formdata中
        if (
          item[Object.keys(item)[0]] != "" ||
          item[Object.keys(item)[0]] != undefined
        ) {
          formData.append(Object.keys(item)[0], item[Object.keys(item)[0]]);
        }
      }
      return formData;
    },
    // 请求标签
    getLabel() {
      this.$http({
        url: "tag/get_tag_list",
        method: "GET",
      })
        .then((res) => {
          this.labelList.splice(0, this.labelList.length);
          // 成功
          if (res.data.status == 0) {
            let data = res.data.data;
            for (let item in data) {
              data[item].forEach((item) => {
                this.labelList.push(item);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 请求分辨率，比例，格式，下拉框的数据
    getdowndata(type) {
      this.$http({
        url: "vw_material/get_else_list",
        method: "GET",
        params: { type: type },
      })
        .then((res) => {
          if (res.data.status == 0) {
            this.resolveData = res.data.resolution_list;
            this.scaleData = res.data.scale_list;
            this.formatData = res.data.format_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 请求上传者下拉框的数据
    getUpUserData() {
      this.$http({
        url: "vw_material/get_company_users",
        method: "GET",
      })
        .then((res) => {
          if (res.data.status == 0) {
            // 将数据处理为级联选择器的格式数据
            this.dealwithData(res.data.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置
    reset() {
      this.date = "";
      this.checkedCities = [];
      this.checkAll = false;
      this.label = "1";
      this.resolving = [];
      this.scale = [];
      this.format = [];
      this.$refs.cascader.$refs.panel.clearCheckedNodes();
    },
    // 筛选
    filter() {
      // 处理上传者数据
      let upuser = "";
      for (let i = 0; i < this.upUser.length; i++) {
        for (let item of this.upUser[i]) {
          if (item != undefined && item != 0) {
            upuser += item + ",";
          }
        }
      }
      let upuser1 = upuser.substr(0, upuser.length - 1);
      let type = this.videoOrimg == "video" ? "1" : "2";
      let formData = this.setRequestData([
        { search_type: type },
        { sort_type: "ut" },
        { sort_type2: 1 },
        { page: 1 },
        { tag_type: this.getLabelId() == "" ? "" : this.label },
        { tag_id_list: this.getLabelId() },
        { search_name: this.searchValue.trim() },
        { start_time: !this.date ? "" : this.date[0] },
        { end_time: !this.date ? "" : this.date[1] },
        { resolution: this.resolving.join(",") },
        { scale: this.scale.join(",") },
        { format: this.format.join(",") },
        { uploader: upuser1 },
      ]);
      if (this.videoOrimg == "video") {
        this.getVideo(formData, "1");
      }
      if (this.videoOrimg == "img") {
        this.getImg(formData, "1");
      }
    },
    // 获取选择的标签id
    getLabelId() {
      let labelId = "";
      // 全选
      if (this.checkedCities.length == this.labelList.length) {
        if(this.checkedCities.length == 0 && this.labelList.length == 0) {
          labelId = "";
        }else{
          labelId = "-1";
        }
      }
      // 全不选
      else if (this.checkedCities.length == 0) {
        labelId = "";
      }
      // 选中一些
      else {
        for (let i of this.checkedCities) {
          for (let j of this.labelList) {
            if (i == j.tag_name) {
              labelId += j.id + ",";
            }
          }
        }
        labelId = labelId.substr(labelId, labelId.length - 1);
      }
      return labelId;
    },
    // 将数据处理为级联选择器的格式数据
    dealwithData(data) {
      // 将第一层对象的key改为 label children
      for (let i = 0; i < data.length; i++) {
        data[i] = {
          label: data[i].department,
          children: data[i].users,
          value: i + "",
        };
      }
      // 将第二层将key为children的数组中数据进行修改
      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (key == "children") {
            for (let j = 0; j < data[i][key].length; j++) {
              data[i][key][j] = {
                label: data[i][key][j].name,
                value: data[i][key][j].user_id + "",
              };
            }
          }
        }
      }
      this.options = data;
    },
    // 选择某一页
    handleCurrentChange(val) {
      this.$nextTick(() => {
        this.$refs.middleListContent.scrollTop = 0;
      });
      this.page = val;
      // 处理上传者数据
      let upuser = "";
      for (let i = 0; i < this.upUser.length; i++) {
        for (let item of this.upUser[i]) {
          if (item != undefined && item != 0) {
            upuser += item + ",";
          }
        }
      }
      let upuser1 = upuser.substr(0, upuser.length - 1);
      // 搜索当前页的数据
      if (this.videoOrimg == "video") {
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 1 },
          { sort_type: this.num == 2 ? "duration" : "ut" },
          { sort_type2: this.DealwithSort(this.num) },
          { page: val },
          { tag_type: this.getLabelId() == "" ? "" : this.label },
          { tag_id_list: this.getLabelId() },
          { search_name: this.searchValue.trim() },
          { start_time: !this.date ? "" : this.date[0] },
          { end_time: !this.date ? "" : this.date[1] },
          { resolution: this.resolving.join(",") },
          { scale: this.scale.join(",") },
          { format: this.format.join(",") },
          { uploader: upuser1 },
        ]);
        this.getVideo(formData);
      }
      // 关键词搜索图片
      if (this.videoOrimg == "img") {
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 2 },
          { sort_type: this.num == 2 ? "duration" : "ut" },
          { sort_type2: this.DealwithSort(this.num) },
          { page: val },
          { tag_type: this.getLabelId() == "" ? "" : this.label },
          { tag_id_list: this.getLabelId() },
          { search_name: this.searchValue.trim() },
          { start_time: !this.date ? "" : this.date[0] },
          { end_time: !this.date ? "" : this.date[1] },
          { resolution: this.resolving.join(",") },
          { scale: this.scale.join(",") },
          { format: this.format.join(",") },
          { uploader: upuser1 },
        ]);
        this.getImg(formData);
      }
    },
    // 判断当前状态是升序还是降序
    DealwithSort(num) {
      // 如果是默认按钮
      if (num == 0) {
        if (this.defaultArrow == "bottom") {
          return 1;
        } else {
          return 0;
        }
      }
      // 如果是上传时间按钮
      if (num == 1) {
        if (this.uploadArrow == "bottom") {
          return 1;
        } else {
          return 0;
        }
      }
      // 如果是时长按钮
      if (num == 2) {
        if (this.timeArrow == "bottom") {
          return 1;
        } else {
          return 0;
        }
      }
    },
  },
  created() {
    this.$store.commit("allroursePage", false);
    // 有传来的标签
    if (this.$route.params.tag_id != undefined) {
      // 打开高级筛选
      this.isshow = true;
      // 默认根据时间倒序搜索视频
      if (this.getCache() == "video") {
        //请求标签
        this.getLabel();
        // 请求上传者下拉框的数据
        this.getUpUserData();
        // 请求视频分辨率，比例，格式下拉框的数据
        this.getdowndata("1");
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 1 },
          {sort_type: "ut"},
          { sort_type2: 1 },
          { tag_id_list: this.$route.params.tag_id },
          { tag_type: 1 },
        ]);
        this.getVideo(formData, "1");
      }
      // 默认根据时间倒序图片
      if (this.getCache() == "img") {
        //请求标签
        this.getLabel();
        // 请求上传者下拉框的数据
        this.getUpUserData();
        // 请求图片分辨率，比例，格式下拉框的数据
        this.getdowndata("2");
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 2 },
          {sort_type: "ut"},
          { sort_type2: 1 },
          { page: 1 },
          { tag_id_list: this.$route.params.tag_id },
          { tag_type: 1 },
        ]);
        this.getImg(formData, "1");
      }
    }
    // 没有传来的标签id
    else {
      // 默认根据时间倒序搜索视频
      if (this.getCache() == "video") {
        //请求标签
        this.getLabel();
        // 请求上传者下拉框的数据
        this.getUpUserData();
        // 请求视频分辨率，比例，格式下拉框的数据
        this.getdowndata("1");
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 1 },
          {sort_type: "ut"},
          { sort_type2: 1 },
        ]);
        this.getVideo(formData);
      }
      // 默认根据时间倒序图片
      if (this.getCache() == "img") {
        //请求标签
        this.getLabel();
        // 请求上传者下拉框的数据
        this.getUpUserData();
        // 请求图片分辨率，比例，格式下拉框的数据
        this.getdowndata("2");
        // 设置请求参数
        let formData = this.setRequestData([
          { search_type: 2 },
          {sort_type: "ut"},
          { sort_type2: 1 },
          { page: 1 },
        ]);
        this.getImg(formData);
      }
    }
  },
  mounted() {
    window.onresize = () => {
      $(".img_outer").each((i, item) => {
        let video_boxW = $(item).width();
        $(item).css("height", video_boxW * 0.587 + "px");
      });
    };
    preventSpaceScroll($(".container"));
    this.timeDefaultShow = new Date();
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
  },
  watch: {
    //切换数据源
    options() {
      this.cascaderKey++;
    },
    videoOrimg() {
      if (this.videoOrimg == "img") {
        this.$nextTick(() => {
          $(".img_size").each((i, item) => {
            let video_boxW = $(item).width();
            $(item).css("height", video_boxW * 0.587 + "px");
          });
        });
      }
    },
    allroursePage(val) {
      if (val == true) {
        this.reload();
      }
    },
  },
  filters: {
    formatScale: function (value) {
      if (value == "1") {
        return "横";
      }
      if (value == "2") {
        return "竖";
      }
      if (value == "3") {
        return "方形";
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("allroursePage", false);
    sessionStorage.removeItem("videoOrimg");
  },
  computed: {
    allroursePage: {
      get() {
        return this.$store.state.allrourse;
      },
      set(val) {
        // this.$store.commit('allroursePage', val)
      },
    },
  },
};
</script>

<style lang='scss' scoped>
// 复选框组
.checkgroup {
  display: flex;
  justify-content: space-around;
  /deep/.el-checkbox {
    width: auto;
    min-width: 120px;
  }
  /deep/.el-checkbox__label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    white-space: pre;
    white-space: pre-wrap;
  }
  .checkedAll {
    padding-left: 110px;
    height: 20px;
    padding-top: 10px;
  }
}
.el-checkbox-group {
  flex: 1;
  max-height: 130px;
  overflow-y: auto;
  margin-top: 10px;
}
/*滚动条样式*/
.el-checkbox-group::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.el-checkbox-group::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;

  background: #b9bac9;
}
.el-checkbox-group::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 0;
  background: #5e5d6e;
}
.Label {
  margin-left: 21px;
  margin-top: 5px;
  font-size: 14px;
}
//高级筛选，视频，图片区域
.container {
  overflow-y: auto;
  // height: 83vh;
  /deep/.el-loading-spinner .circular {
    width: 36px;
    height: 36px;
  }
  /deep/.el-loading-spinner .path {
    stroke: rgba(255, 255, 255, 0.9);
    stroke-width: 3;
  }
  /deep/.el-loading-spinner .el-loading-text {
    color: rgba(255, 255, 255, 0.9);
    margin-top: 10px;
  }
}
// 内容区域滚动条样式
.container::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 7px;

  background: #b9bac9;
}
.container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 7px;
  background: #5e5d6e;
}
.context{
  height: 100%;
}
// 头部搜索框
.header {
  height: 92px;
  background: #161626;
  // margin-top: 1vh;
  display: flex;
  justify-content: center;
  .el-input-group {
    line-height: normal;
    display: inline-table;
    width: 55vw;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    height: 50px;
  }
  /deep/.el-input-group__prepend {
    border-right: 0;
    width: 59px;
    height: 48px;
    background: #2d2d3d;
    opacity: 1;
    border-radius: 6px 0px 0px 6px;
    border: 0px solid #dcdfe6;
    color: rgba(255, 255, 255, 0.9);
    .el-popper[x-placement^="bottom"] {
      margin-top: 1px;
      margin-left: 0px;
    }
  }
  /deep/.el-input__inner {
    height: 50px;
    background: #212130;
    border: 0px solid;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: rgba(255, 255, 255, 0.9);
  }
  /deep/.el-input__suffix {
    right: 20px;
    transition: all 0.3s;
    pointer-events: none;
  }
  .screen {
    margin-top: 20px;
    height: 50px;
    width: 50px;
    opacity: 1;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .searchButton {
    margin-top: 20px;
    height: 50px;
    background: #212130;
    border-radius: 0px 6px 6px 0px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // 图片视频下拉框切换
  /deep/.el-select-dropdown {
    left: 0px !important;
    height: 60px !important;
    border: 0px solid;
    background: #2d2d3d;
    .el-scrollbar {
      border-radius: 5%;
    }
    .el-select-dropdown__list {
      padding: 0px 0;
    }
    .el-select-dropdown__item {
      color: #f7f7f7;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    }
    .el-select-dropdown__item.hover {
      background: inherit;
      font-size: 14px;
      color: #2a9cff;
    }
    .selected {
      color: #2a9cff;
      font-size: 14px;
    }
  }
}
// 筛选展示的容器
.screen_container {
  display: flex;
  background: #161626;
  max-height: 360px;
  padding-bottom: 30px;
  .screen_context {
    flex: 1;
    border: 1px solid #3e3d4c;
    max-height: 330px;
    margin-left: 2px;
    padding-bottom: 10px;
    .block {
      margin-left: 21px;
      margin-top: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.9);
    }
    /deep/.el-range-editor.el-input__inner {
      display: inline-flex;
      align-items: center;
      padding: 3px 10px;
      margin-left: 30px;
      width: 260px;
      height: 30px;
      background: #161626;
      border: 1px solid #5e5f72;
    }
    /deep/.el-range-editor .el-range-input {
      line-height: 1;
      color: rgba(255, 255, 255, 0.9);
      background: #161626;
    }
    /deep/.el-date-editor .el-range__icon {
      font-size: 16px;
      margin-left: 5px;
      color: #999999;
      float: left;
      line-height: 15px;
      width: 12px;
      height: 13.13px;
      margin-right: 10px;
    }
    /deep/.el-date-editor .el-range__close-icon {
      font-size: 14px;
      color: #c0c4cc;
      width: 25px;
      display: inline-block;
      line-height: 25px;
    }
    /deep/.el-date-editor .el-range-separator {
      padding: 0 0px;
      line-height: 25px;
      width: 5%;
      color: rgba(255, 255, 255, 0.9);
    }
    /deep/.el-divider--horizontal {
      display: block;
      height: 1px;
      margin: 5px 0;
      margin-left: 12px;
      margin-right: 12px;
      width: 98.6%;
    }
    /deep/.el-divider {
      background-color: #3e3d4c;
      position: relative;
    }
  }
}
// 标签下拉选择框
.label {
  .Label_select {
    margin-left: 58px;
    width: 123px;
    height: 30px;
    /deep/.el-input__inner {
      background-color: transparent;
      border: 1px solid #5e5f72;
      color: rgba(255, 255, 255, 0.8);
      height: 30px !important;
      line-height: 30px !important;
    }
    /deep/.el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /deep/.el-select-dropdown {
      position: absolute;
      z-index: 1001;
      border-radius: 4px;
      background-color: #2d2d3d;
      box-sizing: border-box;
      border: 1px solid #606266;
    }
    /deep/ {
      .el-select-dropdown__item {
        font-size: 14px;
        padding: 0 20px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 34px;
        line-height: 34px;
        box-sizing: border-box;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.9);
      }
    }
    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #3e3d4c;
    }
    /deep/.el-select-dropdown__list {
      list-style: none;
      padding: 0px 0;
      margin: 0;
      box-sizing: border-box;
    }
    /deep/.el-popper[x-placement^="bottom"] {
      margin-top: 2px;
    }
  }
  .disable_select{
    opacity:0.6;
　　filter:alpha(opacity=60);  //filter 过滤器   兼容IE678
  }
}
// 其他行的下拉选择框
.other {
  margin-left: 21px;
  margin-top: -3px;
  font-size: 14px;
  // 分辨率下拉框
  .resolving {
    margin-left: 58px;
    width: 190px;
    /deep/.el-input__inner {
      background-color: #161626;
      border: 1px solid #5e5f72;
      color: rgba(255, 255, 255, 0.8);
      height: 30px;
      line-height: 30px;
    }
    /deep/.el-select__tags {
      max-width: unset !important;
    }
    /deep/.el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /deep/.el-tag--info {
      background-color: #161626;
      color: rgba(255, 255, 255, 0.6);
    }
    /deep/.el-icon-close {
      background-color: #b1b1b7;
      top: -1px;
    }
    // 点击之后下拉框的样式
    /deep/.el-popper[x-placement^="bottom"] {
      margin-top: 2px;
    }
    /deep/.el-select-dropdown {
      border: 0px solid #e4e7ed;
      background-color: #2d2d3d;
      left: unset !important;
      right: 0px !important;
    }
    /deep/.el-select-dropdown__item {
      font-size: 14px;
      padding: 0 35px;
      color: rgba(255, 255, 255, 0.9);
    }
    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #2d2d3d;
      color: #2a9cff;
    }
    /deep/.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
      color: #409eff;
      background-color: #3e3d4c;
    }
    /deep/.el-scrollbar__bar.is-vertical {
      width: 4px;
      top: 2px;
      background: #5e5d6e;
    }
    /deep/.el-scrollbar__bar.is-vertical > div {
      width: 100%;
      width: 4px !important;
      background: #b9bac9;
    }
    /deep/.el-scrollbar {
      overflow: hidden;
      position: relative;
      height: 200px;
    }
    /deep/.el-scrollbar__wrap {
      height: 100%;
      overflow-x: hidden;
    }
    /deep/.popper__arrow {
      display: none;
    }
  }
  // 比例下拉框
  .scale {
    margin-left: 20px;
    width: 150px;
    /deep/.el-input__inner {
      background-color: #161626;
      border: 1px solid #5e5f72;
      color: rgba(255, 255, 255, 0.8);
      height: 30px;
      line-height: 30px;
    }
    /deep/.el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /deep/.el-tag--info {
      background-color: #161626;
      color: rgba(255, 255, 255, 0.6);
    }
    /deep/.el-icon-close {
      background-color: #b1b1b7;
      top: -1px;
    }
    // 点击之后的样式
    /deep/.el-popper[x-placement^="bottom"] {
      margin-top: 2px;
    }
    /deep/.el-select-dropdown {
      border: 0px solid #e4e7ed;
      background-color: #2d2d3d;
    }
    /deep/.el-select-dropdown {
      right: 0px !important;
      left: unset !important;
    }
    /deep/.el-select-dropdown__item {
      font-size: 14px;
      padding: 0 35px;
      color: rgba(255, 255, 255, 0.9);
    }
    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #2d2d3d;
      color: #2a9cff;
    }
    /deep/.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
      color: #409eff;
      background-color: #3e3d4c;
    }
    /deep/.el-scrollbar__bar.is-vertical {
      width: 4px;
      top: 2px;
      background: #5e5d6e;
    }
    /deep/.el-scrollbar__bar.is-vertical > div {
      width: 100%;
      width: 4px !important;
      background: #b9bac9;
    }
    /deep/.el-scrollbar {
      overflow: hidden;
      position: relative;
      height: unset;
      padding-bottom: 10px;
    }
    /deep/.el-scrollbar__wrap {
      height: 100%;
      overflow-x: hidden;
    }
    /deep/.popper__arrow {
      display: none;
    }
  }
  // 格式下拉框
  .format {
    margin-left: 20px;
    width: 150px;
    /deep/.el-input__inner {
      background-color: #161626;
      border: 1px solid #5e5f72;
      color: rgba(255, 255, 255, 0.8);
      height: 30px;
      line-height: 30px;
    }
    /deep/.el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /deep/.el-tag--info {
      background-color: #161626;
      color: rgba(255, 255, 255, 0.6);
    }
    /deep/.el-icon-close {
      background-color: #b1b1b7;
      top: -1px;
    }
    // 点击之后的样式
    /deep/.el-popper[x-placement^="bottom"] {
      margin-top: 2px;
    }
    /deep/.el-select-dropdown {
      border: 0px solid #e4e7ed;
      background-color: #2d2d3d;
    }
    /deep/.el-select-dropdown {
      right: 0px !important;
      left: unset !important;
    }
    /deep/.el-select-dropdown__item {
      font-size: 14px;
      padding: 0 35px;
      color: rgba(255, 255, 255, 0.9);
    }
    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #2d2d3d;
      color: #2a9cff;
    }
    /deep/.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
      color: #409eff;
      background-color: #3e3d4c;
    }
    /deep/.el-scrollbar__bar.is-vertical {
      width: 4px;
      top: 2px;
      background: #5e5d6e;
    }
    /deep/.el-scrollbar__bar.is-vertical > div {
      width: 100%;
      width: 4px !important;
      background: #b9bac9;
    }
    /deep/.el-scrollbar {
      overflow: hidden;
      position: relative;
      max-height: 200px;
    }
    /deep/.el-scrollbar__wrap {
      height: 100%;
      overflow-x: hidden;
      margin-bottom: unset !important;
    }
    /deep/.el-select-dropdown__list {
      padding: 0px 0;
    }
    /deep/.popper__arrow {
      display: none;
    }
  }
}
// 上传者级联选择器
/deep/.el-cascader:not(.is-disabled):hover {
  border-color: #5e5f72;
  .el-input__inner {
    border-color: #5e5f72;
  }
}
.upload {
  margin-left: 20px;
  line-height: 35px;
  /deep/.el-input__inner:focus {
    border-color: #5e5f72;
  }

  /deep/.el-input__inner {
    background-color: #161626;
    border: 1px solid #5e5f72;
    color: rgba(255, 255, 255, 0.8);
    height: 30px !important;
    line-height: 30px;
  }
  /deep/.el-input__suffix {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/.is-focus {
    .el-input__inner {
      border-color: #5e5f72;
    }
  }
  /deep/.el-tag--info {
    background-color: #161626;
    color: rgba(255, 255, 255, 0.6);
  }
  /deep/.el-icon-close {
    background-color: #b1b1b7;
    top: -1px;
    color: rgba(255, 255, 255, 1);
  }
   /deep/.el-cascader__tags{
    flex-wrap: nowrap;
    .el-tag>span{
      flex: inherit;
    }
  }
}

// 重置跟筛选按钮
.Btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /deep/.el-button {
    margin-left: 0px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.9);
    background: #2d2d3d;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    border: inherit;
    padding: 7px 17px;
  }
}
// 统计跟排序
.totalORorder {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .total {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.9);
    opacity: 0.6;
    margin-left: 3vw;
  }
  .order {
    display: flex;
    margin-right: 4%;
    .default {
      /deep/.el-button {
        height: 3.2vh;
        margin-left: 0px;
        margin-top: -5px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 1;
        border-radius: 6px 0px 0px 6px;
        border-right: 0px solid #555669;
        border-top: 0px solid #555669;
        border-left: 0px solid #555669;
        border-bottom: 0px solid #555669;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .uptime {
      /deep/.el-button {
        height: 3.2vh;
        margin-left: 0px;
        margin-top: -5px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 1;
        border-radius: 0px 0px 0px 0px;
        border-right: 0px solid #555669;
        border-top: 0px solid #555669;
        border-left: 0px solid #555669;
        border-bottom: 0px solid #555669;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .time {
      /deep/.el-button {
        margin-top: -5px;
        height: 3.2vh;
        margin-left: 0px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 1;
        border-radius: 0px 6px 6px 0px;
        border-right: 0px solid #555669;
        border-top: 0px solid #555669;
        border-left: 0px solid #555669;
        border-bottom: 0px solid #555669;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
// 视频区域
.allVideo {
  margin-top: 28px;

  .video {
    position: relative;
    display: inline-block;
    width: 17%;
    margin: 0 1%;
    margin-bottom: 25px;
    &:nth-child(5n + 1) {
      margin-left: 3.5%;
    }
    &:nth-child(5n + 5) {
      margin-right: 3.5%;
    }
    .videoSize {
      width: 100%;
      height: 160px;
      overflow: hidden;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      .gifNode{
        width: 0;
        height: 0;
      }
      .gifNode_show{
        width: auto;
        height: auto;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    // .xgplayer-skin-custom video {
    //   max-width: 281.03px;
    //   max-height: 160px;
    // }
    .duration {
      position: absolute;
      bottom: 30px;
      right: 2px;
      z-index: 99;
      color: rgba(255, 255, 255, 0.9);
      font-size: 12px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 4px 6px;
    }
    .btns {
      display: none;
    }
  }
  .video:hover {
    .btns {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 0;
      right: 0px;
      z-index: 99;
      .download {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .add {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .collet {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 7px;
        cursor: pointer;
      }
    }
    .duration {
      display: none;
    }
  }
}
// 图片区域
.allImg {
  margin-top: 28px;
  .video {
    position: relative;
    // margin-left: 28px;
    display: inline-block;
    width: 17%;
    margin: 0 1%;
    margin-bottom: 25px;
    &:nth-child(5n + 1) {
      margin-left: 3.5%;
    }
    &:nth-child(5n + 5) {
      margin-right: 3.5%;
    }
  }

  .videoSize {
    width: 100%;
    height: 160px;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
  .btns {
    display: none;
  }

  .video:hover {
    .btns {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 0;
      right: 0px;
      z-index: 99;
      .download {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .add {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .collet {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 7px;
        cursor: pointer;
      }
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress {
      display: block;
      position: absolute;
      height: 8px;
      line-height: 8px;
      left: 0px;
      right: 0px;
      outline: none;
      top: 143px;
      z-index: 35;
      width: 281px;
    }
    /deep/.xgplayer-skin-custom .xgplayer-time {
      display: none;
    }
  }
}
.videoTitle {
  cursor: pointer;
  margin-top: 12px;
  width: 100%;
  &:hover {
    color: #2a9cff;
  }
}
// 分页样式
.el-pagination {
  text-align: center;
  padding: 1vh 5px 0px 5px;
  /deep/.btn-prev {
    background: #292833;
    color: rgba(255, 255, 255, 0.9);
  }
  /deep/.btn-next {
    background: #292833;
    color: rgba(255, 255, 255, 0.9);
  }
  /deep/.el-pager li {
    background: #292833;
    color: rgba(255, 255, 255, 0.9);
  }
  /deep/.el-pager li.active {
    color: rgba(255, 255, 255, 0.9);
    background: #409eff;
    border-radius: 20%;
  }
  /deep/.el-pagination__jump {
    color: rgba(255, 255, 255, 0.9);
  }
  /deep/.el-input__inner {
    background: #292833;
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid #3e3d4c;
  }
}
</style>
<style lang='scss'>
.my_material_tooltip.el-tooltip__popper{
    padding: 6px 10px;
    // max-width: 10%;
    // line-height: 18px;
  }
// 上传者级联选择器的全局样式
.upload-user {
  background: none !important;
  border: 0px solid #e4e7ed;
  border-radius: 4px;
  margin: 0px !important;

  .el-cascader__dropdown {
    box-shadow: 0 0 0 0 rgb(115, 8, 8);
  }
  .el-cascader-node:not(.is-disabled):focus {
    background: #3e3d4c;
  }
  .el-checkbox {
    width: 30px;
  }
  .popper__arrow {
    display: none;
  }
  .el-cascader-panel {
    display: flex;
    border-radius: 4px;
    font-size: 14px;
    justify-content: space-around;
    background: transparent;
  }
  .el-scrollbar__wrap {
    height: 100%;
    overflow-x: hidden;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    border: 0px;
    margin-bottom: 0px !important;
  }
  .el-scrollbar {
    overflow-y: auto;
    position: relative;
    color: rgba(255, 255, 255, 0.9);
    border: 0px;
    margin-right: 10px;
    width: 170px;
    max-height: 180px;
    background: #2d2d3d;
    border-radius: 5px;
  }
  .el-cascader-node{
    .el-cascader-node__postfix{
      top: 0;
      height: 34px;
      line-height: 34px;
    }
  }
  .el-cascader-node:hover {
    background: #2d2d3d;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
    top: 2px;
    background: #5e5d6e;
  }
  .el-scrollbar__thumb {
    background: #b9bac9;
  }
}

// 日期选择器
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #4a4b60;
}
.el-date-table td.next-month, .el-date-table td.prev-month{
  color:#818181;
}
.el-date-table td.disabled div {
  background-color: #2d2d3d;
  color: #818181;
}
.el-date-range-picker__header {
  position: relative;
  text-align: center;
  height: 28px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  color: rgba(255, 255, 255, 0.9);
}
.el-date-table td {
  width: 32px;
  height: 30px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
}
.el-date-range-picker__content {
  float: left;
  width: 50%;
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
}
.el-picker-panel__icon-btn {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  border: 0;
  background: 0 0;
  cursor: pointer;
  outline: 0;
  margin-top: 10px;
  margin-left: 5px;
}
.el-date-range-picker table {
  table-layout: fixed;
  width: 100%;
  padding: 0 20px;
}
.el-date-table th {
  padding-top: 15px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: solid 0px #ebeef5 !important;
}
.el-date-range-picker__content.is-left {
  border-right: 0px solid #e4e4e4;
}
.el-picker-panel__icon-btn {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  border: 0;
  background: 0 0;
  cursor: pointer;
  outline: 0;
  margin-top: 8px;
}
.el-date-table th {
  color: rgba(255, 255, 255, 0.9);
  border-bottom: solid 1px #ebeef5;
}
.el-picker-panel {
  color: #606266;
  border: 0px solid #e4e7ed;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5%;
  line-height: 30px;
}
.el-picker-panel__body-wrapper {
  min-width: 513px;
  background: #2d2d3d;
  border-radius: 3%;
  color: rgba(255, 255, 255, 0.9)f;
  padding: 15px 10px;
}
.el-picker-panel__sidebar {
  background: #2d2d3d;

  .el-picker-panel__shortcut {
    color: rgba(255, 255, 255, 0.9)
  }
  .el-picker-panel__shortcut:hover {
    color: #2a9cff;
  }
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  display: none;
}
// 下载对话框
.el-dialog.material-dialog {
  background-color: #292939;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 8px 21px 21px;
  .el-dialog__header {
    text-align: center;
    padding: 0;
    padding-bottom: 6px;
    .el-dialog__title {
      color: rgba(255, 255, 255, 0.9);
      font-size: 16px;
      letter-spacing: 30px;
    }
    .el-dialog__headerbtn {
      top: 10px;
      .el-dialog__close {
        color: rgba(255, 255, 255, 0.9);
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    color: rgba(255, 255, 255, 0.9);
    background-color: #0f0f1e;
    font-size: 14px;
    padding: 38px 69px 0 69px;
    .radio_check {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .disable_radio {
        .el-radio__inner {
          background-color: rgba(255, 255, 255, 0.5);
          border: inherit;
        }
        .el-radio__label {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .el-radio {
        width: 50%;
        margin-right: 0;
        margin-bottom: 18px;
        color: rgba(255, 255, 255, 0.9);
      }
      .el-radio__inner {
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 3px solid rgba(255, 255, 255, 0.9);
          &::after {
            background-color: inherit;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    padding: 20px 69px 45px 69px;
    background-color: #0f0f1e;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      .el-button {
        border-radius: 20px;
        border: 1px solid #5e5f72;
        background-color: #0f0f1e;
        color: rgba(255, 255, 255, 0.9);
        padding: 6px 15px;
      }
    }
  }
}
</style>
